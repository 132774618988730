import React, { useCallback, useEffect } from "react";
import DadosBasicosVendedorPfSantanderForm from "./DadosBasicosVendedorPfSantanderForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";

const DadosBasicosVendedorPfSantanderFormConnected = ({
  handleGoTo11,
  eventsVendedorPf,
  participante: vendedor,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      genero: generos,
      documentoPessoal: documentosPessoais,
      profissao: profissoes,
      nacionalidade: nacionalidades,
      perfilRenda: perfisRenda
    },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === vendedor.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo11();
    },
    [handleGoTo11, setParticipante, vendedor]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({ required: true }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({ required: true }),
      email: validators.email({ required: true }),
      genero: validators.object({ required: true }),
      documentoPessoal: validators.object({ required: true }),
      numeroDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      orgaoDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      ufOrgaoDocumentoPessoal: validators.string({
        required: true,
      }),
      dataExpedicaoDocumentoPessoal: validators.date({ required: true }),
      telefone: validators.phone({ required: true }),
      dadosProfissionais: {
        cargo: validators.string({ required: true }),
      },
      nacionalidade: validators.object({ required: true }),
      nomeProcurador: validators.string({ required: true }),
      cpfProcurador: validators.cpf({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        dataNascimento: "",
        genero: null,
        documentoPessoal: null,
        email: "",
        telefone: "",
        possuiProcuracao: false,
        numeroDocumentoPessoal: "",
        orgaoDocumentoPessoal: "",
        ufOrgaoDocumentoPessoal: "",
        dataExpedicaoDocumentoPessoal: "",
        nomeProcurador: "",
        cpfProcurador: "",
        nacionalidade: null,
        ...vendedor,
        dadosProfissionais: {
          possuiFuncionarios: null,
          cargo: "",
          ...vendedor.dadosProfissionais,
        },
      }),
      [vendedor]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais-vendedor-pf",
            participante: {
              id: vendedor?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
              dataNascimento: transformToDate(values.dataNascimento),
              idGenero: values.genero?.id,
              possuiProcuracao: values.possuiProcuracao,
              idDocumentoPessoal: values.documentoPessoal?.id,
              numeroDocumentoPessoal: values.numeroDocumentoPessoal,
              orgaoDocumentoPessoal: values.orgaoDocumentoPessoal,
              ufOrgaoDocumentoPessoal: values.documentoPessoal?.descricao === "RNE" ? null : values.ufOrgaoDocumentoPessoal,
              dataExpedicaoDocumentoPessoal: transformToDate(
                values.dataExpedicaoDocumentoPessoal
              ),
              email: values.email,
              telefone: values.telefone,
              nacionalidade: values.nacionalidade,
              perfilRenda: values.perfilRenda,
              dadosProfissionais: {
                cargo: values.dadosProfissionais?.cargo,
                possuiFuncionarios: values.dadosProfissionais.possuiFuncionarios,
              },
              nomeProcurador: values.possuiProcuracao ? values.nomeProcurador : null,
              cpfProcurador: values.possuiProcuracao ? removeNonDigitsFromString(values.cpfProcurador) : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, vendedor, operacao, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      cpfCnpj: "",
      nome: "",
      dataNascimento: "",
      genero: null,
      documentoPessoal: null,
      email: "",
      telefone: "",
      possuiProcuracao: false,
      numeroDocumentoPessoal: "",
      orgaoDocumentoPessoal: "",
      ufOrgaoDocumentoPessoal: "",
      dataExpedicaoDocumentoPessoal: "",
      nacionalidade: null,
      ...vendedor,
      dadosProfissionais: {
        possuiFuncionarios: null,
        cargo: null,
        ...vendedor.dadosProfissionais,
      },
    });
  }, [vendedor, reset]);


  const perfilRendaSelecionado = formProps.watch("perfilRenda");
  const isEmpresarioAutonomoOuProdutorRural = perfisRenda.filter(perfil =>
    ["EMPRESARIO", "AUTONOMO / PROFISSIONAL", "PRODUTOR RURAL"].some(substring => perfil?.descricao.includes(substring))
  ).some(obj => obj.id === perfilRendaSelecionado?.id)

  useEffect(() => {
    if (formProps.getValues().dadosProfissionais.possuiFuncionarios) {
      return;
    }
    if (isEmpresarioAutonomoOuProdutorRural) {
      formProps.setValue("dadosProfissionais.possuiFuncionarios", false);
    } else {
      formProps.setValue("dadosProfissionais.possuiFuncionarios", null);
    }
  }, [perfilRendaSelecionado])

  const possuiProcuracao = formProps.watch("possuiProcuracao")
  const isEstrangeiro = formProps.watch("documentoPessoal")?.id === 4;

  return (
    <DadosBasicosVendedorPfSantanderForm
      {...otherProps}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      documentosPessoais={documentosPessoais}
      profissoes={profissoes}
      generos={generos}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={vendedor.fluxo}
      events={eventsVendedorPf}
      nacionalidades={nacionalidades}
      isEmpresarioAutonomoOuProdutorRural={isEmpresarioAutonomoOuProdutorRural}
      perfisRenda={perfisRenda}
      possuiProcuracao={possuiProcuracao}
      isEstrangeiro={isEstrangeiro}
    />
  );
};

export default DadosBasicosVendedorPfSantanderFormConnected;
