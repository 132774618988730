import basicFlow from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions } from "../actions/parceiro.actions";
import {
  authenticatedRequest,
  destinies,
  unauthenticatedRequest,
} from "../utils/api";
import { toast } from "react-toastify";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import { getRouteArguments } from "../selectors/routes.selectors";
import {
  fileToBase64,
  getFileNameFromHeader,
  joinDateTime,
  transformFromDateTime,
  transformToDateTime,
} from "../utils/basic";
import { partner } from "../utils/localStorage";
import { actions as temaActions } from "../actions/tema.actions";
import { saveAs } from "file-saver";

const tratarDetalheParceiro = ({ response }) => {
  const dataHoraAgendamento = transformFromDateTime(
    response.data.dataHoraAgendamento
  );

  response.data = {
    ...response.data,
    _dataHoraAgendamento: response.data.dataHoraAgendamento,
    dataHoraAgendamento: dataHoraAgendamento,
    dataAgendamento: !!dataHoraAgendamento
      ? dataHoraAgendamento.split(" ")[0]
      : "",
    horaAgendamento: !!dataHoraAgendamento
      ? dataHoraAgendamento.split(" ")[1]
      : "",
    _parceiroIndicacao: response.data.parceiroIndicacao,
    parceiroIndicacao: !!response.data.parceiroIndicacao
      ? { codigo: response.data.parceiroIndicacao }
      : null,
  };
};

const criarParceiro = basicFlow({
  actionGenerator: actions.criarParceiro,
  transform: (values) => {
    const parceiro = partner.get();

    return {
      ...values,
      parceiroIndicacao: parceiro?.id,
    };
  },
  api: (values) => {
    return unauthenticatedRequest({
      url: "/parceiro",
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* () {
    partner.clear();
    yield put(temaActions.reiniciarTema());
    toast.info(
      "Sua solicitação será analisada e em breve um dos nossos consultores entrará em contato."
    );
    yield put(routeActions.redirectTo(routes.LOGIN));
  },
});
const atualizarParceiro = basicFlow({
  actionGenerator: actions.atualizarParceiro,
  transform: (payload) => {
    return {
      ...payload,
      dataHoraAgendamento: transformToDateTime(
        joinDateTime(payload.dataAgendamento, payload.horaAgendamento)
      ),
      banco: payload.banco?.codigo ?? payload.banco,
    };
  },
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${id}`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "put",
      body: values,
    });
  },
  preSuccess: tratarDetalheParceiro,
  postSuccess: () => {
    toast.info("Informações salvas com sucesso.");
  },
});

const obterParceiro = basicFlow({
  actionGenerator: actions.obterParceiro,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/parceiro/${id}`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
  preSuccess: tratarDetalheParceiro,
});

const obterPromotores = basicFlow({
  actionGenerator: actions.obterPromotores,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/usuarios?perfil=&parceiro=${id}`,
      isResourceService: true,
      destiny: destinies.AUTH,
      method: "get",
    });
  },
});

const validarIdentificador = basicFlow({
  actionGenerator: actions.validarIdentificador,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/parceiro/valido`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      queryParams: values,
      method: "get",
    });
  },
  postSuccess: ({ original }) => {
    if (original.callback) {
      original.callback(true);
    }
  },
  postFailure: ({ original }) => {
    if (original.callback) {
      original.callback(false);
    }
  },
});

const atualizarCondicaoComercial = basicFlow({
  actionGenerator: actions.atualizarCondicaoComercial,
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${id}/condicoes-comerciais`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      body: values,
      method: "put",
    });
  },
  preSuccess: tratarDetalheParceiro,
  postSuccess: () => {
    toast.info("Condição comercial atualizada com sucesso.");
  },
});

const listarParceiros = basicFlow({
  actionGenerator: actions.listarParceiros,
  transform: (payload) => {
    return {
      situacao: payload.situacao,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/parceiros",
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      queryParams: values,
      method: "get",
    });
  },
});

const pesquisarCep = basicFlow({
  actionGenerator: actions.pesquisarCep,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `/ws/${value}/json/`,
      method: "get",
      destiny: destinies.CEP,
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
});

const obterBancos = basicFlow({
  actionGenerator: actions.obterBancos,
  api: () => {
    return authenticatedRequest({
      url: `/dominios/bancos`,
      method: "get",
      destiny: destinies.PARCEIRO,
    });
  },
});
const obterDominioParceiros = basicFlow({
  actionGenerator: actions.obterDominioParceiros,
  api: () => {
    return authenticatedRequest({
      url: "/dominios/parceiros",
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
});

const refresh = basicFlow({
  actionGenerator: actions.refresh,
  transform: (original) => {
    return {
      id: original.id,
    };
  },
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/parceiro/${id}`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
  postSuccess: ({ original }) => {
    if (!!original?.acao){
      toast.success(`Ação "${original?.acao?.nome}" executada com sucesso`);
    }
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const validar = basicFlow({
  actionGenerator: actions.validar,
  api: (values) => {
    return unauthenticatedRequest({
      url: "/parceiro/query",
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
      queryParams: values,
    });
  },
  postSuccess: function* ({ response }) {
    if (!!response.data) {
      partner.save(response.data);
      const { urlLogo, corPrimaria, corSecundaria } = response.data;
      yield put(
        temaActions.aplicarTema({ urlLogo, corSecundaria, corPrimaria })
      );
    } else {
      const parceiro = partner.get();
      if (!!parceiro) {
        const { urlLogo, corPrimaria, corSecundaria } = parceiro;
        yield put(
          temaActions.aplicarTema({ urlLogo, corSecundaria, corPrimaria })
        );
      }
    }
  },
});

const obterDocumentos = basicFlow({
  actionGenerator: actions.obterDocumentos,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/parceiro/${id}/documentos`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
});
const uploadDocumento = basicFlow({
  actionGenerator: actions.uploadDocumento,
  transform: function* (payload) {
    const arquivos = [];

    for (const f of payload.files) {
      arquivos.push({
        nome: f.name,
        base64: yield fileToBase64(f),
      });
    }

    return {
      id: payload.id,
      arquivos,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/documento-parceiro/${values.id}`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "put",
      body: values,
    });
  },
  postSuccess: () => {
    toast.success("Arquivo enviado com sucesso");
  },
});
const downloadDocumento = basicFlow({
  actionGenerator: actions.downloadDocumento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/artefato/${id}`,
      isResourceService: true,
      responseType: "arraybuffer",
      destiny: destinies.DOCUMENTO,
      method: "get",
    });
  },
  postSuccess: ({ response }) => {
    const fileName = getFileNameFromHeader(
      response.headers,
      "content-disposition"
    );

    if (!!fileName) {
      saveAs(new Blob([response.data]), fileName);
    }
  },
});
const downloadTemplate = basicFlow({
  actionGenerator: actions.downloadTemplate,
  api: ({ id, preencherAutomaticamente }) => {
    return authenticatedRequest({
      url: `/documento-parceiro/${id}/template?preencherAutomaticamente=${preencherAutomaticamente}`,
      isResourceService: true,
      responseType: "arraybuffer",
      destiny: destinies.DOCUMENTO,
      method: "get",
    });
  },
  postSuccess: ({ response }) => {
    const fileName = getFileNameFromHeader(
      response.headers,
      "content-disposition"
    );

    if (!!fileName) {
      saveAs(new Blob([response.data]), fileName);
    }
  },
});
const removerArquivo = basicFlow({
  actionGenerator: actions.removerArquivo,
  api: ({ id, documento }) => {
    return authenticatedRequest({
      url: `/documento-parceiro/${documento}/artefato/${id}`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "delete",
    });
  },
  postSuccess: () => {
    toast.success("Arquivo removido com sucesso");
  },
});

const obterTimes = basicFlow({
  actionGenerator: actions.obterTimes,
  api: (id) => {
    return authenticatedRequest({
      url: `/parceiro/${id}/time`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
  postSuccess: ({ original }) => {
    if (original.callback) {
      original.callback(true);
    }
  }
});

const gravarTime = basicFlow({
  actionGenerator: actions.gravarTime,
  api: ({ idParceiro, ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${idParceiro}/time`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    if (original.callback) {
      original.callback(true);
    }
    yield put(actions.obterTimes.request(original.idParceiro))
    toast.success("Filial cadastrada com sucesso!")
  }
});

const atualizarTime = basicFlow({
  actionGenerator: actions.atualizarTime,
  api: ({ idParceiro, ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${idParceiro}/time`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    if (original.callback) {
      original.callback(true);
    }
    yield put(actions.obterTimes.request(original.idParceiro))
    toast.success("Filial atualizada com sucesso!")
  }
});

const excluirTime = basicFlow({
  actionGenerator: actions.excluirTime,
  api: ({ idParceiro, ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${idParceiro}/time`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "delete",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    if (original.callback) {
      original.callback(true);
    }
    yield put(actions.obterTimes.request(original.idParceiro))
    toast.success("Filial excluída com sucesso!")
  }
});

function* cadastroParceiroRouteWatcher() {
  yield routeWatcher([routes.CADASTRO_PARCEIRO], function* () {
    const payload = yield select(getRouteArguments);
    if (!!payload?.identificador) {
      yield put(actions.validar.request(payload));
    } else {
      const parceiro = partner.get();
      if (!!parceiro) {
        const { urlLogo, corPrimaria, corSecundaria } = parceiro;
        yield put(
          temaActions.aplicarTema({ urlLogo, corSecundaria, corPrimaria })
        );
      }
    }
  });
}
function* listaParceirosRouteWatcher() {
  yield routeWatcher([routes.PARCEIROS], function* () {
    yield put(actions.listarParceiros.request({ situacao: "X" }));
  });
}
function* detalhesParceiroRouteWatcher() {
  yield routeWatcher([routes.DETALHES_PARCEIRO], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterParceiro.request(payload));
    yield put(actions.obterPromotores.request(payload));
    yield put(actions.obterBancos.request());
    yield put(actions.obterDominioParceiros.request());
  });
}

export const sagas = [
  criarParceiro.watcher(),
  obterParceiro.watcher(),
  obterPromotores.watcher(),
  listarParceiros.watcher(),
  pesquisarCep.watcher(),
  obterBancos.watcher(),
  atualizarParceiro.watcher(),
  atualizarCondicaoComercial.watcher(),
  refresh.watcher(),
  validar.watcher(),
  validarIdentificador.watcher(),
  obterDominioParceiros.watcher(),
  uploadDocumento.watcher(),
  downloadDocumento.watcher(),
  removerArquivo.watcher(),
  downloadTemplate.watcher(),
  obterDocumentos.watcher(),
  obterTimes.watcher(),
  gravarTime.watcher(),
  atualizarTime.watcher(),
  excluirTime.watcher(),
  cadastroParceiroRouteWatcher(),
  detalhesParceiroRouteWatcher(),
  listaParceirosRouteWatcher(),
];
