import React, { useCallback } from "react";
import { NumberFormatBase } from "react-number-format";
import { removeNonDigitsFromString } from "../../utils/basic";

const CpfCnpjTextField = React.forwardRef(
	({ onChange, type = "both", ...other }, ref) => {
		const formatCpfCnpj = useCallback(
			(value) => {
				const stripped = removeNonDigitsFromString(value);
				const size = !!stripped ? stripped.length : 0;

				if ((size > 11 && type === "both") || type === "cnpj") {
					let finalValue = stripped.slice(0, 2);
					if (stripped.length > 2) {
						finalValue += "." + stripped.slice(2, 5);
					}
					if (stripped.length > 5) {
						finalValue += "." + stripped.slice(5, 8);
					}
					if (stripped.length > 8) {
						finalValue += "/" + stripped.slice(8, 12);
					}
					if (stripped.length > 12) {
						finalValue += "-" + stripped.slice(12, 14);
					}
					return finalValue;
				} else {
					let finalValue = stripped.slice(0, 3);
					if (stripped.length > 3) {
						finalValue += "." + stripped.slice(3, 6);
					}
					if (stripped.length > 6) {
						finalValue += "." + stripped.slice(6, 9);
					}
					if (stripped.length > 9 && type === "cpf") {
						finalValue += "-" + stripped.slice(9, 11);
					}
					if (stripped.length > 9 && type === "both") {
						finalValue += "-" + stripped.slice(9);
					}
					return finalValue;
				}
			},
			[type]
		);

		return (
			<NumberFormatBase
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							value: values.formattedValue,
						},
					});
				}}
				format={formatCpfCnpj}
			/>
		);
	}
);

export default CpfCnpjTextField;
