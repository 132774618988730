import { isValid as isValidCpf } from "@fnando/cpf";
import { isValid as isValidCnpj } from "@fnando/cnpj";
import { parseDateUsingFormat, removeNonDigitsFromString } from "./basic";
import { isAfter, isBefore } from "date-fns";

export const requiredValidation = (value) => {
  if (value !== undefined && value !== null && value !== "") return true;
  return "Campo obrigatório";
};
const validDate = (value) => {
  try {
    if (value instanceof Date) return true;
    if (typeof value !== "string") return false;
    if (value.length !== 10) return false;
    const [dia, mes, ano] = value.split("/");
    if (!dia || !mes | !ano) return false;
    const diaNumero = parseInt(dia, 10);
    const mesNumero = parseInt(mes, 10);
    const anoNumero = parseInt(ano, 10);
    const date = new Date(anoNumero, mesNumero - 1, diaNumero, 0, 0, 0);
    return (
      date.getFullYear() === anoNumero &&
      date.getMonth() === mesNumero - 1 &&
      date.getDate() === diaNumero
    );
  } catch (e) {
    return false;
  }
};

const minDate = (value, min) => {
  const date = parseDateUsingFormat(value, "dd/MM/yyyy");
  const minDate = parseDateUsingFormat(min, "dd/MM/yyyy");

  return !isBefore(date, minDate);
};
const maxDate = (value, max) => {
  const date = parseDateUsingFormat(value, "dd/MM/yyyy");
  const maxDate = parseDateUsingFormat(max, "dd/MM/yyyy");

  return !isAfter(date, maxDate);
};

const validDateTime = (value) => {
  try {
    if (value instanceof Date) return true;
    if (typeof value !== "string") return false;
    if (value.length !== 16) return false;
    const [data, horario] = value.split(" ");
    const [dia, mes, ano] = data.split("/");
    const [hora, minuto] = horario.split(":");
    if (!dia || !mes || !ano || !hora || !minuto) return false;
    const diaNumero = parseInt(dia, 10);
    const mesNumero = parseInt(mes, 10);
    const anoNumero = parseInt(ano, 10);
    const horaNumero = parseInt(hora, 10);
    const minutoNumero = parseInt(minuto, 10);
    const date = new Date(
      anoNumero,
      mesNumero - 1,
      diaNumero,
      horaNumero,
      minutoNumero,
      0
    );
    return (
      date.getFullYear() === anoNumero &&
      date.getMonth() === mesNumero - 1 &&
      date.getDate() === diaNumero &&
      date.getHours() === horaNumero &&
      date.getMinutes() === minutoNumero
    );
  } catch (e) {
    return false;
  }
};
const validTime = (value) => {
  try {
    if (value instanceof Date) return true;
    if (typeof value !== "string") return false;
    if (value.length !== 5) return false;
    const [hora, minuto] = value.split(":");
    if (!hora || !minuto) return false;
    const horaNumero = parseInt(hora, 10);
    const minutoNumero = parseInt(minuto, 10);
    const dataBase = new Date();
    const date = new Date(
      dataBase.getFullYear(),
      dataBase.getMonth(),
      dataBase.getDay(),
      horaNumero,
      minutoNumero,
      0
    );
    return date.getHours() === horaNumero && date.getMinutes() === minutoNumero;
  } catch (e) {
    return false;
  }
};
const validRg = (value) => {
  if (!value) return true; // RG não é obrigatório
  const rgPattern = /^\d{1,2}\.\d{3}\.\d{3}-\d{1}$/;
  return rgPattern.test(value) || "RG inválido";
};
export const validEmail = (value) =>
  !!value
    ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) || "E-mail inválido"
    : true;
export const validUrl = (urlString) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString) || "Site inválido";
};

const validators = {
  object: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) =>
          !required ||
          (value !== undefined && value !== null && value !== {}) ||
          "Campo obrigatório",
        ...custom,
      },
    };
  },
  array: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) =>
          !required ||
          (value !== undefined && Array.isArray(value) && value.length > 0) ||
          "Campo obrigatório",
        ...custom,
      },
    };
  },
  string: ({ required = false, maxLength, length, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        maxLength: (value) =>
          !maxLength ||
          value.length <= maxLength ||
          `Tamanho máximo (${maxLength})`,
        length: (value) =>
          !length || value.length === length || `Tamanho inválido (${length})`,
        ...custom,
      },
    };
  },
  cpf: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) => !value || isValidCpf(value) || "CPF inválido",
        ...custom,
      },
    };
  },
  cnpj: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) => isValidCnpj(value) || "CNPJ inválido",
        ...custom,
      },
    };
  },
  cpfCnpj: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) => {
          if (value !== undefined && value !== null && value.length > 0) {
            const stripped = removeNonDigitsFromString(value);
            if (parseInt(stripped) === 0) return "CPF/CNPJ inválido";
            if (stripped.length < 14) {
              return isValidCpf(value) || "CPF inválido";
            }
            return isValidCnpj(value) || "CNPJ inválido";
          }
        },
        ...custom,
      },
    };
  },
  email: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: validEmail,
        ...custom,
      },
    };
  },
  phone: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) =>
          !!value && value.length > 0
            ? (value?.length >= 13 &&
                value?.length <= 15 &&
                value?.slice(0, 2) !== "(0") ||
              "Telefone inválido"
            : true,
        ...custom,
      },
    };
  },
  date: ({ required = false, min = null, max = null, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) =>
          value === null ||
          value.length === 0 ||
          validDate(value) ||
          "Data inválida",
        min: (value) =>
          min === null ||
          value === null ||
          value.length === 0 ||
          minDate(value, min) ||
          `Data deve ser no mínimo ${min}`,
        max: (value) =>
          max === null ||
          value === null ||
          value.length === 0 ||
          maxDate(value, max) ||
          `Data deve ser no máximo ${max}`,
        ...custom,
      },
    };
  },
  dateTime: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) =>
          value === null ||
          value.length === 0 ||
          validDateTime(value) ||
          "Data/hora inválida",
        ...custom,
      },
    };
  },
  time: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) =>
          value === null ||
          value.length === 0 ||
          validTime(value) ||
          "Hora inválida",
        ...custom,
      },
    };
  },
  number: ({ required = false, positive = false, length, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        positive: (value) =>
          !positive ||
          value === "" ||
          value === null ||
          value > 0 ||
          "Valor deve ser maior do que 0",
        length: (value) =>
          !length || value.length === length || `Tamanho inválido (${length})`,
        ...custom,
      },
    };
  },
  url: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: validUrl,
        ...custom,
      },
    };
  },
  rg: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: validRg,
        ...custom,
      },
    };
  },
};

export default validators;
