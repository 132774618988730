import itauLogoPng from "../assets/bancos/itau_logo.png";
import santanderLogoPng from "../assets/bancos/santander_logo.png";
import bradescoLogoPng from "../assets/bancos/bradesco_logo.png";
import bariLogoPng from "../assets/bancos/bari_logo.png";
import cashmeLogoPng from "../assets/bancos/cashme_logo.png";
import galleriaLogoPng from "../assets/bancos/galleria_logo.png";
import c6LogoPng from "../assets/bancos/c6_logo.png";
import libraLogoPng from "../assets/bancos/libra_logo.png";
import interLogoPng from "../assets/bancos/inter_logo.png";
import caixaLogoPng from "../assets/bancos/caixa_logo.jpg";
import creditasLogoPng from "../assets/bancos/creditas_logo.jpg";
import leadAquisicaoLogoPng from "../assets/bancos/lead_aquisicao_logo_v2.png";
import leadConsorcioLogoPng from "../assets/bancos/lead_consorcio_logo_v2.png";
import leadCgiLogoPng from "../assets/bancos/lead_cgi_logo_v2.png";
import leadLogoPng from "../assets/bancos/lead_aquisicao_logo_bw.png";

import itauPng from "../assets/bancos/itau.png";
import santanderPng from "../assets/bancos/santander.png";
import bradescoPng from "../assets/bancos/bradesco.png";
import bariPng from "../assets/bancos/bari.png";
import cashmePng from "../assets/bancos/cashme.png";

export const logos = {
  "lead-aquisicao": leadAquisicaoLogoPng,
  "lead-consorcio": leadConsorcioLogoPng,
  "lead-cgi": leadCgiLogoPng,
  lead: leadLogoPng,
  itau: itauLogoPng,
  c6: c6LogoPng,
  libra: libraLogoPng,
  santander: santanderLogoPng,
  bradesco: bradescoLogoPng,
  bari: bariLogoPng,
  cashme: cashmeLogoPng,
  galleria: galleriaLogoPng,
  inter: interLogoPng,
  caixa: caixaLogoPng,
  creditas: creditasLogoPng,
};
export const expandedLogos = {
  itau: itauPng,
  santander: santanderPng,
  bradesco: bradescoPng,
  bari: bariPng,
  cashme: cashmePng,
};

export const estados = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const ETAPAS = [
  {
    key: "total",
    label: "Todos os Clientes",
    id: 0,
  },
  {
    key: "leads",
    label: "Contato Inicial",
    id: 1,
  },
  {
    key: "preenchimento",
    label: "Envio da Proposta",
    id: 2,
  },
  {
    key: "credito",
    label: "Análise de Crédito",
    id: 3,
  },
  {
    key: "documentos",
    label: "Cadastro e Envio de Documentos",
    id: 4,
  },
  {
    key: "juridico",
    label: "Avaliação e Análise Jurídica",
    id: 5,
  },
  {
    key: "confirmacao",
    label: "Confirmação de Valores",
    id: 6,
  },
  {
    key: "emissao",
    label: "Emissão e Contrato",
    id: 7,
  },
  {
    key: "registro",
    label: "Registro do Contrato",
    id: 8,
  },
  {
    key: "liberacao",
    label: "Liberação de Recursos",
    id: 9,
  },
];

export const FORMA_CONTATO = {
  W: { id: "W", descricao: "WhatsApp" },
  E: { id: "E", descricao: "E-mail" },
  T: { id: "T", descricao: "Telefone" },
};

export const PENSIONISTAS = {
  AP: "APOSENTADO",
  PE: "PENSIONISTA",
};

export const DETAIL_TABS = {
  COMENTARIOS: "999",
  DOCUMENTOS: "100",
  CONFIGURACOES: "101",
};

export const FORMA_CORRECAO = {
  tr: "TR",
  cdi: "CDI",
  poupanca: "Poupança",
  ipca: "IPCA",
  pref: "Pré-fixado",
};
export const TIPO_AMORTIZACAO = {
  S: "SAC",
  P: "PRICE",
};

export const NOME_GRUPO = {
  P: "Parceiro",
  O: "Operação",
  C: "Compradores",
  V: "Vendedores",
  N: "Outros Proponentes",
  I: "Imóvel",
};
export const STATUS_DOCUMENTO = {
  PE: "Pendente",
  IN: "Indexado",
  AN: "Analisado",
};

export const TIPO_PROPRIETARIO_IMOVEL = {
  CO: {
    label: "Cônjuge",
    enum: "CONJUGE",
  },
  PA: {
    label: "Pai ou Mãe",
    enum: "PAI_MAE",
  },
  FI: {
    label: "Filho ou Filha",
    enum: "FILHO_FILHA",
  },
};

export const TIPO_RESIDENTE_IMOVEL = {
  CL: {
    label: "Cliente",
    enum: "CLIENTE",
  },
  PR: {
    label: "Proprietário(a)",
    enum: "PROPRIETARIO",
  },
  IN: {
    label: "Inquilino(a)",
    enum: "INQUILINO",
  },
};
