import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Skeleton,
  styled,
  Menu,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Card, Title, Tooltip, SplitButton } from "../../components";
import Dropzone from "react-dropzone";
import { DeleteForever, ExpandMore, MoreVert } from "@mui/icons-material";
import iconePdf from "../../assets/pdf.png";
import iconeImagem from "../../assets/imagem.png";
import iconeArquivo from "../../assets/outros.png";
import formatters from "../../utils/formatters";
import { transientOptions } from "../../styles/theme";
import { HorizontalLoader } from "../../components/loaders";
import AdicionarDocumentoManualDialogConnected from "../workflow/AdicionarDocumentoManualDialog.connected";
import {
  NOME_GRUPO,
  STATUS_DOCUMENTO,
} from "../../constants/generic.constants";

const StyledSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadiuses.cards};
`;
const OptionButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;
const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    background-color: transparent;
    overflow: hidden;
  }

  &.MuiAccordion-root:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.borderRadiuses.cards};
    border-top-right-radius: ${({ theme }) => theme.borderRadiuses.cards};
  }

  &.MuiAccordion-root:last-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadiuses.cards};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadiuses.cards};
  }

  & .MuiAccordionSummary-root.Mui-expanded {
    border-radius: ${({ theme }) => theme.borderRadiuses.cards};
  }

  & .MuiAccordionSummary-root.Mui-disabled {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.gray._50};
  }

  & .MuiAccordionSummary-root.Mui-disabled h3 {
    color: ${({ theme }) => theme.colors.secondary._300};
  }

  & .MuiAccordionSummary-root {
    padding: 0 1.5rem;
    background: ${({ theme }) => theme.colors.fixed.white};
  }

  & .MuiAccordionSummary-content,
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 1.5rem 0;
    align-items: center;
    justify-content: space-between;
  }

  & .MuiAccordionDetails-root {
    padding: 0;
    margin-top: 1rem;
  }
`;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const DocumentosContainer = styled("div")``;
const ItemContainer = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.gray._100};
  border-radius: 1rem;
  padding: 1rem;
`;
const DocumentoContainer = styled("div")`
  &:not(:first-of-type) {
    padding-top: 1rem;
  }

  &:not(:last-of-type) {
    padding-bottom: 1rem;
  }

  & + & {
    border-top: 1px dashed ${({ theme }) => theme.colors.gray._100};
  }
`;
const NomeDocumento = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.secondary._700};
  margin-bottom: 0.5rem;
`;
const ContainerArquivo = styled(Grid, transientOptions)`
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._50};
  border: 1px dashed
    ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._200};
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._700};
`;
const NomeArquivo = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: block;
  color: ${({ theme }) => theme.colors.gray._400};
`;
const HorarioArquivo = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.tiny};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 800;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray._200};
`;
const Descricao = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
`;
const SituacaoDocumento = styled("span")`
  padding: 0.3rem 0.5rem;
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.operacao.situacao};
  font-weight: 700;
  color: ${({ theme, _situacao_ }) =>
    _situacao_ === "AN"
      ? theme.colors.success._600
      : _situacao_ === "IN"
      ? theme.colors.success._600
      : theme.colors.error._600};
  background-color: ${({ theme, _situacao_ }) =>
    _situacao_ === "AN"
      ? theme.colors.success._100
      : _situacao_ === "IN"
      ? theme.colors.success._100
      : theme.colors.error._100};
  border-radius: 6px;
`;
const TipoArquivo = styled("img")`
  width: 32px;
  height: auto;
`;
const StyledIconButton = styled(IconButton)`
  color: inherit;
`;
const AdicionarDocumentoButton = styled(Button)`
  margin-right: 1rem;
  pointer-events: visible;
  background-color: ${({ theme }) => theme.colors.fixed.white};
`;

const getTipoArquivo = (tipo) => {
  if (tipo.indexOf("pdf") >= 0) {
    return iconePdf;
  }
  if (tipo.indexOf("image") >= 0) {
    return iconeImagem;
  }
  return iconeArquivo;
};

const Documento = ({
  data,
  perfil,
  encerrado,
  handleUpload,
  handleDownload,
  handleDelete,
  handleTemplate,
  handleExcluirDocumento,
  toggleEnvioDocumento,
  uploading,
  deleting,
  downloading,
  templating,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const handleClick = (event, checklist) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(checklist);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpen(null);
  };

  const optionsModelos = [
    {
      text: "BAIXAR MODELO",
      autofill: true,
    },
    {
      text: "BAIXAR MODELO (EM BRANCO)",
      autofill: false,
    },
  ];

  return (
    <DocumentoContainer>
      <Grid
        container
        mb={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <NomeDocumento>{data.nome}</NomeDocumento>
            </Grid>
            <Grid item>
              <SituacaoDocumento _situacao_={data.situacao}>
                {STATUS_DOCUMENTO[data.situacao]}
              </SituacaoDocumento>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            {data.situacao !== "AN" && data.template && !!data.habilitado && (
              <Grid item>
                <Tooltip title={"Baixar modelo"} arrow>
                  {data.padrao === "PA" ? (
                    <SplitButton
                      variant={"outlined"}
                      size={"small"}
                      _color_={"primary"}
                      loading={templating.indexOf(data.id) >= 0}
                      loaderColor={"brand"}
                      options={optionsModelos}
                      onClick={(v) =>
                        handleTemplate({ ...data, preencherAutomaticamente: v })
                      }
                    />
                  ) : (
                    <Button
                      variant={"outlined"}
                      size={"small"}
                      _color_={"primary"}
                      loading={templating.indexOf(data.id) >= 0}
                      loaderColor={"brand"}
                      onClick={() => handleTemplate(data)}
                    >
                      BAIXAR MODELO
                    </Button>
                  )}
                </Tooltip>
              </Grid>
            )}
            {data.situacao !== "AN" &&
              ((!encerrado && !!data.habilitado && !data.encerrado) ||
                (!!data.aberturaManual && !!data.habilitado)) && (
                <Grid item>
                  <Dropzone
                    onDrop={(acceptedFiles, fileRejections) =>
                      handleUpload(data, acceptedFiles, fileRejections)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <span {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Tooltip title={"Anexar arquivo"} arrow>
                          <Button
                            variant={"outlined"}
                            size={"small"}
                            loaderColor={"gray"}
                            loading={uploading.indexOf(data.id) >= 0}
                            _color_={"gray"}
                          >
                            ANEXAR ARQUIVO
                          </Button>
                        </Tooltip>
                      </span>
                    )}
                  </Dropzone>
                </Grid>
              )}
            {perfil === "OPERACAO" &&
              (data.situacao === "PE" ||
                data.encerrado ||
                data.aberturaManual) && (
                <Grid item>
                  <OptionButton onClick={(e) => handleClick(e, data)}>
                    <MoreVert />
                  </OptionButton>
                  <StyledMenu
                    anchorEl={anchorEl}
                    open={!!open}
                    onClose={handleClose}
                  >
                    {!!data.encerrado && !data.aberturaManual && (
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e);
                          toggleEnvioDocumento(e, open?.id, true);
                        }}
                      >
                        Habilitar modificações no documento
                      </MenuItem>
                    )}
                    {!!data.encerrado && !!data.aberturaManual && (
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e);
                          toggleEnvioDocumento(e, open?.id, false);
                        }}
                      >
                        Bloquear modificações no documento
                      </MenuItem>
                    )}
                    {data.situacao === "PE" && (
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e);
                          handleExcluirDocumento(e, data.id);
                        }}
                      >
                        Excluir documento
                      </MenuItem>
                    )}
                  </StyledMenu>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Descricao>{data.descricao}</Descricao>
      <Grid container gap={1}>
        {data.arquivos.map((arquivo) => (
          <ContainerArquivo item key={arquivo.id}>
            <Grid container columnSpacing={1} alignItems={"center"}>
              <Grid item onClick={() => handleDownload(arquivo)}>
                <TipoArquivo src={getTipoArquivo(arquivo.tipo)} />
              </Grid>
              <Grid
                item
                flexDirection={"column"}
                onClick={() => handleDownload(arquivo)}
              >
                <NomeArquivo>{arquivo.nome}</NomeArquivo>
                <HorarioArquivo>
                  {formatters.dates.simple(
                    new Date(arquivo.dataHoraCriacao),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                </HorarioArquivo>
              </Grid>
              {data.situacao !== "AN" &&
                ((!encerrado && !!data.habilitado && !data.encerrado) ||
                  (!!data.aberturaManual && !!data.habilitado)) && (
                  <Grid item>
                    <Tooltip title={"Excluir arquivo"} arrow>
                      <StyledIconButton
                        onClick={() => handleDelete(arquivo, data.id)}
                      >
                        {deleting.indexOf(arquivo.id) >= 0 ? (
                          <HorizontalLoader color={"gray"} size={24} />
                        ) : (
                          <DeleteForever />
                        )}
                      </StyledIconButton>
                    </Tooltip>
                  </Grid>
                )}
            </Grid>
            {downloading.indexOf(arquivo.id) >= 0 && <LinearProgress />}
          </ContainerArquivo>
        ))}
      </Grid>
    </DocumentoContainer>
  );
};

const Item = ({ data, ...other }) => {
  return (
    <Grid item xs={12}>
      <ItemContainer>
        <Title as={"h5"} _color_={"gray"} _margin_={"0 0 0.25rem 0"}>
          {data.nome}
        </Title>
        <DocumentosContainer>
          {data.documentos.map((documento) => (
            <Documento key={documento.id} data={documento} {...other} />
          ))}
        </DocumentosContainer>
      </ItemContainer>
    </Grid>
  );
};

const Grupos = ({ data, ...other }) => {
  return data.grupos.map((grupo) => (
    <Grid item xs={12} lg={6} key={data.tipo + grupo.tipo}>
      <Card>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"1rem"}
        >
          <Title as={"h4"}>{NOME_GRUPO[grupo.tipo]}</Title>
        </Grid>
        <Grid container spacing={3}>
          {grupo.itens.map((item) => (
            <Item
              key={item.id + item.tipo}
              data={item}
              encerrado={data.encerrado}
              {...other}
            />
          ))}
        </Grid>
      </Card>
    </Grid>
  ));
};

const ChecklistsSkeleton = () => (
  <StyledSkeleton variant={"rounded"} width={"100%"} height={390} />
);

const DocumentosTab = ({
  data,
  perfil,
  loading,
  handleAddDocument,
  downloadingAll,
  handleDownloadAll,
  possuiDocumentosIndexados,
  toggleEnvioDocumentosGrupo,
  ...other
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const handleClick = (event, checklist) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(checklist);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpen(null);
  };

  return (
    <>
      {!!loading && (
        <Grid item xs={12}>
          <ChecklistsSkeleton />
        </Grid>
      )}
      {!loading && (
        <>
          {possuiDocumentosIndexados && perfil === "OPERACAO" && (
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <Button
                  loading={downloadingAll}
                  fullWidth
                  onClick={handleDownloadAll}
                >
                  BAIXAR DOCUMENTOS
                </Button>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            {data.map((checklist) => (
              <StyledAccordion
                key={checklist.tipo}
                elevation={0}
                disabled={checklist.grupos.length === 0}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Grid container spacing={1} justifyContent={"space-between"}>
                    <Grid item xs={12} md={"auto"}>
                      <Badge badgeContent={checklist.pendentes} color="error">
                        <Title as={"h3"} _margin_={"0 0.5rem 0 0"}>
                          {checklist.descricao}
                        </Title>
                      </Badge>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                      {perfil === "OPERACAO" &&
                        (!checklist.encerrado || checklist.aberturaManual) && (
                          <AdicionarDocumentoButton
                            _color_={"gray"}
                            variant={"outlined"}
                            disabled={false}
                            size={"small"}
                            onClick={(e) =>
                              handleAddDocument(
                                e,
                                checklist.tipo,
                                checklist.aberturaManual
                              )
                            }
                          >
                            ADICIONAR DOCUMENTO
                          </AdicionarDocumentoButton>
                        )}
                      {perfil === "OPERACAO" && checklist.encerrado && (
                        <IconButton onClick={(e) => handleClick(e, checklist)}>
                          <MoreVert />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grupos data={checklist} perfil={perfil} {...other} />
                  </Grid>
                </AccordionDetails>
              </StyledAccordion>
            ))}
            <StyledMenu anchorEl={anchorEl} open={!!open} onClose={handleClose}>
              <MenuItem
                onClick={(e) => {
                  handleClose(e);
                  toggleEnvioDocumentosGrupo(
                    e,
                    open?.tipo,
                    !open?.aberturaManual
                  );
                }}
              >
                {open?.aberturaManual
                  ? "Bloquear envio de documentos"
                  : "Permitir envio de documentos"}
              </MenuItem>
            </StyledMenu>
            <AdicionarDocumentoManualDialogConnected />
          </Grid>
        </>
      )}
    </>
  );
};

export default DocumentosTab;
