import DocumentosTab from "./DocumentosTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesOperacao,
  getDocumentos,
  getQuantidadeDocumentosPendentes,
  isBaixandoArquivos,
  isBaixandoTemplates,
  isCarregandoDocumentos,
  isEnviandoComplementoOperacao,
  isRemovendoArquivos,
  isSubindoArquivos,
} from "../../../../selectors/operacao.selectors";
import { useCallback } from "react";
import { actions } from "../../../../actions/operacao.actions";
import { actions as workflowActions } from "../../../../actions/workflow.actions";
import { getUsuarioLogado } from "../../../../selectors/usuario.selectors";
import { actions as documentoActions } from "../../../../actions/documentos.actions";

const DocumentosTabConnected = ({ handleGoTo998 }) => {
  const dispatch = useDispatch();
  const operacao = useSelector(getDetalhesOperacao);
  const data = useSelector(getDocumentos);
  const uploading = useSelector(isSubindoArquivos);
  const downloading = useSelector(isBaixandoArquivos);
  const templating = useSelector(isBaixandoTemplates);
  const deleting = useSelector(isRemovendoArquivos);
  const loading = useSelector(isCarregandoDocumentos);
  const submitting = useSelector(isEnviandoComplementoOperacao);
  const usuario = useSelector(getUsuarioLogado);

  const dataComPendencias = data.map((c) => ({
    ...c,
    pendentes: getQuantidadeDocumentosPendentes([c]),
  }));

  const handleUpload = useCallback(
    ({ id, padrao, arquivos }, ok, nok) => {
      if (nok.length > 0) {
      } else {
        switch (padrao) {
          case "VC":
            if (arquivos.length > 0) {
              dispatch(actions.uploadDocumento.request({ id, files: ok }));
            } else {
              dispatch(
                documentoActions.exibirValidadeCredito(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "LA":
            if (arquivos.length > 0) {
              dispatch(actions.uploadDocumento.request({ id, files: ok }));
            } else {
              dispatch(
                documentoActions.exibirLaudoAvaliacao(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "ES":
            if (arquivos.length > 0) {
              dispatch(actions.uploadDocumento.request({ id, files: ok }));
            } else {
              dispatch(
                documentoActions.exibirCondicoesComerciais(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "IA":
            dispatch(
              documentoActions.exibirIA(true, {
                id,
                files: ok,
              })
            );
            break;
          default:
            dispatch(actions.uploadDocumento.request({ id, files: ok }));
            break;
        }
      }
    },
    [dispatch, operacao]
  );
  const handleDownload = useCallback(
    ({ tipo, id }) => {
      dispatch(actions.downloadDocumento.request({ tipo, id }));
    },
    [dispatch]
  );
  const handleTemplate = useCallback(
    ({ id, preencherAutomaticamente = false }) => {
      dispatch(actions.downloadTemplate.request({ id, preencherAutomaticamente }));
    },
    [dispatch]
  );
  const handleDelete = useCallback(
    ({ id }, documento) => {
      dispatch(actions.removerArquivo.request({ id, documento }));
    },
    [dispatch]
  );
  const handleAddDocument = useCallback(
    (e, chk) => {
      e.stopPropagation();
      dispatch(
        workflowActions.exibirDocumentoManual({
          show: true,
          id: operacao.id,
          tipo: "operacao",
          checklist: chk,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleEnviar = useCallback(() => {
    dispatch(
      actions.enviarComplemento.request({
        id: operacao.id,
      })
    );
  }, [dispatch, operacao]);

  return (
    <DocumentosTab
      data={dataComPendencias}
      loading={loading}
      handleUpload={handleUpload}
      handleDownload={handleDownload}
      handleDelete={handleDelete}
      handleTemplate={handleTemplate}
      handleAddDocument={handleAddDocument}
      uploading={uploading}
      downloading={downloading}
      deleting={deleting}
      templating={templating}
      submitting={submitting}
      handleEnviar={handleEnviar}
      handleGoBack={handleGoTo998}
      perfil={usuario.perfil}
    />
  );
};

export default DocumentosTabConnected;
