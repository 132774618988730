import { createSelector } from "reselect";

export const getDocumentos = (state) => state.documentos;

export const getDominiosOperacao = createSelector(
  getDocumentos,
  (state) => state.dominios.operacao
);

export const getValidadeCredito = createSelector(
  getDocumentos,
  (state) => state.validadeCredito
);
export const getCondicoesComerciais = createSelector(
  getDocumentos,
  (state) => state.condicoesComerciais
);
export const getLaudoAvaliacao = createSelector(
  getDocumentos,
  (state) => state.laudoAvaliacao
);

export const getIA = createSelector(getDocumentos, (state) => state.ia);
