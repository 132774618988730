import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import rootSagas from "./sagas";
import {
  enhancer as routesEnhancer,
  middleware as routesMiddleware,
} from "./routes";
import { actions as themeActions } from "./actions/tema.actions";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares = [sagaMiddleware, routesMiddleware];

if (process.env.NODE_ENV === `development`) {
  const loggerMiddleware = createLogger({
    predicate: (getState, action) => action.meta?.silent !== true,
  });

  middlewares.push(loggerMiddleware);
}

const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(routesEnhancer, applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSagas);

store.dispatch(themeActions.atualizar());

export default store;
