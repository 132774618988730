import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../../components";
import {
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
  IntegerTextField,
  PercentTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";

const OutrasInformacoesImovelDefaultForm = ({
  fluxo,
  formProps,
  tipoProduto,
  handleSubmit,
  parteImovel,
  handleChangeParteImovel,
  submitting,
  events,
  operacao,
  handleGoTo100,
  formPropsPercentualAquisicao,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo100}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras informações</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Número de Dormitórios/Suítes"
                      name={"numeroDormitorios"}
                      formProps={formProps}
                      inputProps={{ maxLength: 2, allowNegative: false }}
                      InputProps={{
                        inputComponent: IntegerTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledSwitch
                      label="Possui Depósito ou Vaga Autônoma?"
                      name={"vagaAutonoma"}
                      formProps={formProps}
                      tooltip={
                        "Uma vaga autônoma é aquela que tem uma escritura e fração ideal própria. Isso faz com que ela possa ser vendida junto com imóvel Principal, nas não está diretamente ligada ao apartamento ou imóvel Principal sendo necessário apresentar Matricula a parte do imóvel Principal. Já uma vaga determinada é aquela que é parte da escritura do imóvel, mas não é autônoma."
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledSwitch
                      label="Imóvel Financiado?"
                      name={"imovelFinanciado"}
                      tooltip={
                        "O interveniente quitante é uma operação para vender um imóvel financiado, onde o comprador quitará a dívida atual com um novo financiamento realizado em outra instituição financeira. A nova instituição deve quitar as parcelas restantes do primeiro empréstimo ou financiamento, elas irão se comunicar entre-si para checar o saldo devedor remanescente."
                      }
                      formProps={formProps}
                    />
                  </Grid>
                  {tipoProduto === "I" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <ControlledSwitch
                          label="Primeira Aquisição no SFH?"
                          name={"primeiraAquisicaoSfh"}
                          tooltip={
                            "O desconto de primeiro imóvel, de acordo com a Lei 6015/73, oferece um benefício de 50% nas taxas de registro e escritura para pessoas que estão financiando sua primeira residência pelo Sistema Financeiro de Habitação (SFH). Para obtê-lo, o comprador deve preencher uma Declaração de Primeira Aquisição, afirmando que o imóvel é sua primeira aquisição residencial, conforme a lei. Isso torna a compra do imóvel mais acessível financeiramente. Recomenda-se verificar os procedimentos específicos junto ao banco e ao cartório responsável pelo registro do imóvel."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Está adquirindo parte do Imóvel?"
                          name={"adquirindoParteImovel"}
                          onChange={handleChangeParteImovel}
                          tooltip={
                            "Um dos cônjuges pode exercer o seu direito de preferência e adquirir a fração pertencente ao outro, passando assim a se tornar proprietário exclusivo do bem; Quando o imóvel está quitado, a pessoa pode usar o FGTS para comprar até 80% dos 50% da parte do ex-conjuge, desde que tenha sido a pessoa que perdeu o direito de morar no imóvel, portanto, quem ficou sem o imóvel após a separação. Além disso, é necessário preencher os demais requisitos do regulamento do FGTS, como não ter outro imóvel, por exemplo. Em imóveis que ainda estejam financiados, não pode usar FGTS para comprar a parte do outro. O FGTS só pode ser usado como amortização. Para comprar a outra parte, não é Zecessária a outorga conjugal (anuência). Se o bem era dos dois, o cônjuge que vai vender não deve anuir, mas manifestar vontade própria de vender sua parte."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      {parteImovel && (
                        <>
                          <Grid item xs={12} md={4}>
                            <ControlledTextField
                              label="% da Parte a ser Adquirida"
                              name={"parteAdquirida"}
                              formProps={formProps}
                              InputProps={{
                                inputComponent: PercentTextField,
                              }}
                              inputProps={{
                                allowNegative: false,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <ControlledTextField
                              label="Valor de compra/venda da Parte a ser Adquirida"
                              name={"valorParteAdquirida"}
                              formProps={formProps}
                              InputProps={{
                                inputComponent: CurrencyTextField,
                              }}
                              inputProps={{
                                allowNegative: false,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {tipoProduto === "H" && (
                    <Grid item xs={12} md={6}>
                      <ControlledSwitch
                        label="Imóvel Próprio?"
                        name={"imovelProprio"}
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Title as={"h5"}>Percentual de Aquisição</Title>
                  </Grid>
                  {operacao.participantes
                    .filter((p) => p.tipoParticipante === "CO")
                    .map((comprador, index) => (
                      <Grid item xs={12} key={comprador.id}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={8}>
                            <ControlledTextField
                              formProps={formPropsPercentualAquisicao}
                              name={`participantes.${index}.nome`}
                              value={comprador.nome}
                              label={"Nome do Comprador"}
                              inputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <ControlledTextField
                              formProps={formPropsPercentualAquisicao}
                              name={`participantes.${index}.percentualAquisicaoImovel`}
                              validationKey={
                                "participantes.percentualAquisicaoImovel"
                              }
                              label={"% de aquisição"}
                              onChange={() =>
                                formPropsPercentualAquisicao.formState.errors &&
                                formPropsPercentualAquisicao.trigger()
                              }
                              inputProps={{
                                readOnly:
                                  operacao.participantes.filter(
                                    (p) => p.tipoParticipante === "CO"
                                  ).length === 1,
                              }}
                              InputProps={{
                                inputComponent: PercentTextField,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesImovelDefaultForm;
