import { Grid, styled } from "@mui/material";
import React from "react";
import { transientOptions } from "../../styles/theme";
import Title from "../Title";
import { HorizontalLoader } from "../loaders";
import InformarMotivoAcaoDialogConnected from "../../business/workflow/InformarMotivoAcaoDialog.connected";
import { Button } from "../index";
import InformarNumeroPropostaDialogConnected from "../../business/workflow/InformarNumeroPropostaDialog.connected";
import {
  AccessTime,
  PrecisionManufacturing,
  WarningRounded,
} from "@mui/icons-material";

const EtapasContainer = styled("div")`
  margin: 1.5rem 0 0;
  border-top: solid 2px ${({ theme }) => theme.colors.gray._50};
  padding-top: 0.75rem;
`;
const Etapa = styled("div", transientOptions)`
  background-color: ${({ theme, _cancel_, _automacao_ }) =>
    _automacao_ ? theme.colors.info._100 : theme.colors.gray._50};
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: ${({ theme }) => theme.fonts.family.default};
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
`;
const EtapaTitle = styled("div", transientOptions)`
  color: ${({ theme, _cancel_, _automacao_ }) =>
    _cancel_
      ? theme.colors.error._600
      : _automacao_
      ? theme.colors.info._700
      : theme.colors.secondary._700};

  font-size: ${({ theme }) => theme.fonts.size.parceiro.etapa.title};
  font-weight: 700;
`;
const EtapaInstrucao = styled("div")`
  color: ${({ theme, _cancel_ }) =>
    _cancel_ ? theme.colors.error._600 : theme.colors.gray._600};
  font-size: ${({ theme }) => theme.fonts.size.parceiro.etapa.subtitle};
  font-weight: 400;
  margin: 0.5rem 0;
  white-space: pre-wrap;
`;
const ActionContainer = styled("div")`
  margin-top: 1rem;
`;
const ActionContainerTitle = styled("div")`
  color: ${({ theme, _cancel_ }) =>
    _cancel_ ? theme.colors.error._600 : theme.colors.gray._900};
  font-size: ${({ theme }) => theme.fonts.size.parceiro.etapa.actionTitle};
  font-weight: 300;
  margin-bottom: 0.5rem;
`;
const ActionButton = styled(Button)``;

const BadgeContainer = styled("div")`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const TimerText = styled("div", transientOptions)`
  color: ${({ theme, _automacao_ }) =>
    _automacao_ ? theme.colors.info._700 : theme.colors.secondary._700};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.gray._400};
  color: ${({ theme }) => theme.colors.fixed.white};
  border-radius: 15px;
  & svg {
    height: 16px;
    width: 16px;
  }
`;

const AutomacaoText = styled("div", transientOptions)`
  color: ${({ theme, _automacao_ }) =>
    _automacao_ ? theme.colors.info._700 : theme.colors.secondary._700};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.info._700};
  color: ${({ theme }) => theme.colors.fixed.white};
  border-radius: 15px;
  & svg {
    height: 16px;
    width: 16px;
  }
`;

const EtapasAndamento = ({
  etapasAbertas,
  handleAcao,
  acting,
  podeCancelar,
  dadosMotivo,
  handleCloseMotivoAcao,
  handleCancelar,
  numeroProposta,
  handleCloseNumeroProposta,
  parceiro,
  isParceiro,
  perfil,
  handleCancelarParceiro,
  handleSuspenderParceiro,
  handleReativarParceiro,
}) => {
  if (!etapasAbertas || !etapasAbertas.length) {
    // não tem fases abertas, tem que verificar se é parceiro ativo e é OPERACAO
    if (perfil !== "OPERACAO") {
      return null;
    }
    if (!isParceiro || !["P", "A", "S"].includes(parceiro?.situacao)) {
      return null;
    }
  }

  const getData = (date) => {
    const diferenca = new Date().getTime() - new Date(date).getTime();
    const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));
    const horas = Math.floor(
      (diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));

    let resposta = "Aberto faz ";
    return (resposta +=
      dias > 0
        ? `${dias} dias`
        : horas > 0
        ? `${horas} horas`
        : `${minutos} minutos`);
  };

  return (
    <>
      <EtapasContainer>
        <Title as={"h3"} _margin_={"0 0 1rem 0"}>
          Etapas em andamento
        </Title>
        <Grid container spacing={2}>
          {(!isParceiro || ["C", "S"].indexOf(parceiro?.situacao) < 0) &&
            etapasAbertas.map((etapa) => (
              <Grid item key={etapa.id} xs={12} md={6} lg={4} xl={3}>
                <Etapa _automacao_={etapa.automacao}>
                  <EtapaTitle _automacao_={etapa.automacao}>
                    {etapa.nome}
                  </EtapaTitle>
                  <BadgeContainer>
                    {etapa.automacao && (
                      <AutomacaoText>
                        <PrecisionManufacturing />
                        Processo de automação em andamento
                      </AutomacaoText>
                    )}
                    <TimerText>
                      <AccessTime />
                      {getData(etapa.dataHoraCriacao)}
                    </TimerText>
                  </BadgeContainer>
                  <Grid
                    container
                    display={"flex"}
                    flex={1}
                    direction={"column"}
                    justifyContent={"space-between"}
                  >
                    {!!etapa.instrucoes ? (
                      <EtapaInstrucao>{etapa.instrucoes}</EtapaInstrucao>
                    ) : (
                      <div />
                    )}
                    {etapa.acoes && etapa.acoes.length > 0 && (
                      <ActionContainer>
                        <ActionContainerTitle>
                          Ações disponíveis:
                        </ActionContainerTitle>
                        {!!acting && (
                          <HorizontalLoader color={"brand"} size={40} />
                        )}
                        {!acting && (
                          <Grid container spacing={1}>
                            {etapa.acoes
                              .filter((a) => a.visivel)
                              .map((acao) => (
                                <Grid item xs={12} key={acao.id}>
                                  <ActionButton
                                    _color_={
                                      acao.padraoAcao === "C" ||
                                      acao.padraoAcao === "U"
                                        ? "error"
                                        : acao.padraoAcao === "R"
                                        ? "gray"
                                        : "primary"
                                    }
                                    size={"small"}
                                    variant={"outlined"}
                                    fullWidth={true}
                                    onClick={() => handleAcao(etapa, acao)}
                                  >
                                    {acao.nome}
                                  </ActionButton>
                                </Grid>
                              ))}
                          </Grid>
                        )}
                      </ActionContainer>
                    )}
                  </Grid>
                </Etapa>
              </Grid>
            ))}
          {podeCancelar && (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Etapa _cancel_={true}>
                <EtapaTitle _cancel_={true}>
                  Cancelamento da Operação
                </EtapaTitle>
                <Grid
                  container
                  display={"flex"}
                  flex={1}
                  direction={"column"}
                  justifyContent={"space-between"}
                >
                  <EtapaInstrucao _cancel_={true}>
                    <Grid container spacing={2} flexWrap={"nowrap"}>
                      <Grid item>
                        <WarningRounded fontSize={"large"} />
                      </Grid>
                      <Grid item>
                        <b>Cuidado!!</b>
                        <br />
                        Esta ação não poderá ser revertida!
                      </Grid>
                    </Grid>
                  </EtapaInstrucao>
                  <ActionContainer>
                    <ActionContainerTitle>
                      Ações disponíveis:
                    </ActionContainerTitle>
                    {!!acting && <HorizontalLoader color={"brand"} size={40} />}
                    {!acting && (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <ActionButton
                            _color_={"error"}
                            size={"small"}
                            variant={"outlined"}
                            fullWidth={true}
                            onClick={handleCancelar}
                          >
                            Cancelar Operação
                          </ActionButton>
                        </Grid>
                      </Grid>
                    )}
                  </ActionContainer>
                </Grid>
              </Etapa>
            </Grid>
          )}
          {isParceiro && !!parceiro && perfil === "OPERACAO" && (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Etapa _cancel_={true}>
                <EtapaTitle _cancel_={true}>Manutenção do Parceiro</EtapaTitle>
                <Grid
                  container
                  display={"flex"}
                  flex={1}
                  direction={"column"}
                  justifyContent={"space-between"}
                >
                  {["P", "A"].includes(parceiro.situacao) ? (
                    <EtapaInstrucao _cancel_={true}>
                      <Grid container spacing={2} flexWrap={"nowrap"}>
                        <Grid item>
                          <WarningRounded fontSize={"large"} />
                        </Grid>
                        <Grid item>
                          <b>Alerta!</b>
                          <br />
                          Ao executar esta ação, todos os usuário associados ao
                          parceiros serão suspensos.
                        </Grid>
                      </Grid>
                    </EtapaInstrucao>
                  ) : (
                    <div />
                  )}
                  <ActionContainer>
                    <ActionContainerTitle>
                      Ações disponíveis:
                    </ActionContainerTitle>
                    {!!acting && <HorizontalLoader color={"brand"} size={40} />}
                    {!acting && (
                      <Grid container spacing={1}>
                        {["C", "S"].includes(parceiro.situacao) ? (
                          <>
                            <Grid item xs={12}>
                              <ActionButton
                                _color_={"gray"}
                                size={"small"}
                                variant={"outlined"}
                                fullWidth={true}
                                onClick={handleReativarParceiro}
                              >
                                Reativar Parceiro
                              </ActionButton>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <ActionButton
                                _color_={"gray"}
                                size={"small"}
                                variant={"outlined"}
                                fullWidth={true}
                                onClick={handleSuspenderParceiro}
                              >
                                Suspender Parceiro
                              </ActionButton>
                            </Grid>
                            <Grid item xs={12}>
                              <ActionButton
                                _color_={"error"}
                                size={"small"}
                                variant={"outlined"}
                                fullWidth={true}
                                onClick={handleCancelarParceiro}
                              >
                                Cancelar Parceiro
                              </ActionButton>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    )}
                  </ActionContainer>
                </Grid>
              </Etapa>
            </Grid>
          )}
        </Grid>
      </EtapasContainer>
      <InformarMotivoAcaoDialogConnected
        dados={dadosMotivo}
        handleClose={handleCloseMotivoAcao}
      />
      <InformarNumeroPropostaDialogConnected
        dados={numeroProposta}
        handleClose={handleCloseNumeroProposta}
      />
    </>
  );
};

export default EtapasAndamento;
