import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { connectRoutes, NOT_FOUND } from "redux-first-router";
import restoreScroll from "redux-first-router-restore-scroll";
import { getCurrentRoute } from "./selectors/routes.selectors";
import {
  actions as routeActions,
  types as routes,
} from "./actions/rotas.actions";
import * as pages from "./business/pages";
import { toast } from "react-toastify";
import {
  getPerfil,
  getUsuarioLogado,
  isUsuarioLogado,
} from "./selectors/usuario.selectors";
import { getCodigoOperacaoExterno } from "./selectors/autenticacao.selectors";

const routePages = {
  [routes.LOGIN]: {
    component: pages.LoginPage,
  },
  [routes.LOGIN_EXTERNO]: {
    component: pages.LoginExternoPage,
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.EsqueciSenhaPage,
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenhaPage,
  },
  [routes.DASHBOARD]: {
    component: pages.DashboardPage,
  },
  [routes.CADASTRO_PARCEIRO]: {
    component: pages.CadastroParceiroPage,
  },
  [routes.PRIMEIRO_ACESSO_PARCEIRO]: {
    component: pages.PrimeiroAcessoPage,
  },
  [routes.PARCEIROS]: {
    component: pages.ListaParceirosPage,
    restricted: ["OPERACAO"],
  },
  [routes.DETALHES_PARCEIRO]: {
    component: pages.DetalhesParceiroPage,
    restricted: ["OPERACAO", "PARCEIRO_MASTER", "CADASTRO_MASTER"],
  },
  [routes.CALCULADORA_INTERNA]: {
    component: pages.CalculadoraInternaPage,
    restricted: [
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "OPERACIONAL",
      "FINANCEIRO",
      "LIDER",
    ],
  },
  [routes.CALCULADORA_EXTERNA]: {
    component: pages.CalculadoraExternaPage,
  },
  [routes.CLIENTES]: {
    component: pages.ListaClientesPage,
    restricted: [
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "OPERACIONAL",
      "FINANCEIRO",
      "LIDER",
    ],
  },
  [routes.DETALHES_CLIENTE]: {
    component: pages.DetalhesClientePage,
    restricted: [
      "CLIENTE",
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "OPERACIONAL",
      "FINANCEIRO",
      "EXTERNO",
      "LIDER",
    ],
  },
  [routes.OPERACOES]: {
    component: pages.ListaOperacoesPage,
    restricted: [
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "OPERACIONAL",
      "FINANCEIRO",
      "LIDER",
    ],
  },
  [routes.DETALHES_OPERACAO]: {
    component: pages.DetalhesOperacaoPage,
    restricted: [
      "CLIENTE",
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "OPERACIONAL",
      "FINANCEIRO",
      "EXTERNO",
      "LIDER",
    ],
  },
  [routes.APROVACAO_MENOR]: {
    component: pages.AprovacaoMenorPage,
    restricted: [
      "CLIENTE",
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "OPERACIONAL",
      "FINANCEIRO",
      "EXTERNO",
      "LIDER",
    ],
  },
  //TODO arrumar o component para um component próprio dos dados bancários
  [routes.DADOS_BANCARIOS]: {
    component: pages.DadosBancariosOperacaoPage,
    restricted: true,
  },
  [routes.SIMULADOR]: {
    component: pages.SimuladorInternoPage,
    restricted: [
      "OPERACAO",
      "PARCEIRO_MASTER",
      "PROMOTOR",
      "LIDER",
      "OPERACIONAL",
      "FINANCEIRO",
    ],
  },
  [routes.SIMULADOR_CONTINUACAO]: {
    component: pages.SimuladorInternoPage,
    restricted: [
      "CLIENTE",
      "OPERACAO",
      "PARCEIRO_MASTER",
      "PROMOTOR",
      "LIDER",
      "OPERACIONAL",
      "FINANCEIRO",
    ],
  },
  [routes.PREENCHIMENTO_OPERACAO]: {
    component: pages.PreenchimentoOperacaoPage,
    restricted: true,
  },
  [routes.COMPLEMENTO_OPERACAO]: {
    component: pages.ComplementoOperacaoPage,
    restricted: true,
  },
  [routes.CONFIRMACAO_VALORES]: {
    component: pages.ConfirmacaoValoresPage,
    restricted: true,
  },

  [routes.CONTROLE_FINANCEIRO]: {
    component: pages.ControleFinanceiroPage,
    restricted: ["OPERACAO", "PARCEIRO_MASTER", "OPERACIONAL", "FINANCEIRO"],
  },
  [routes.USUARIOS]: {
    component: pages.ListaUsuariosPage,
    restricted: ["OPERACAO", "PARCEIRO_MASTER"],
  },
  [routes.DETALHES_USUARIO]: {
    component: pages.DetalhesUsuarioPage,
    restricted: true,
  },
  [routes.CADASTRO_USUARIO]: {
    component: pages.CadastroUsuarioPage,
    restricted: ["OPERACAO", "PARCEIRO_MASTER"],
  },
  [routes.MATERIAL_APOIO]: {
    component: pages.MaterialApoioPage,
    restricted: [
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "LIDER",
      "OPERACIONAL",
      "FINANCEIRO",
    ],
  },
  [routes.ACADEMY]: {
    component: pages.TayaAcademyPage,
    restricted: [
      "OPERACAO",
      "PARCEIRO_MASTER",
      "CADASTRO_MASTER",
      "PROMOTOR",
      "LIDER",
      "OPERACIONAL",
      "FINANCEIRO",
    ],
  },

  [routes.TERMOS_USO]: {
    component: pages.TermosUsoPage,
  },
  [routes.POLITICA_PRIVACIDADE]: {
    component: pages.PoliticaPrivacidadePage,
  },
};
export const routePaths = {
  [routes.LOGIN]: "/",
  [routes.LOGIN_EXTERNO]: "/externo/:id",

  [routes.TERMOS_USO]: "/termos-uso",
  [routes.POLITICA_PRIVACIDADE]: "/politica-privacidade",

  [routes.CONTROLE_FINANCEIRO]: "/controle-financeiro",
  [routes.USUARIOS]: "/usuarios",
  [routes.DETALHES_USUARIO]: "/usuario/:id",
  [routes.CADASTRO_USUARIO]: "/cadastro-usuario",
  [routes.MATERIAL_APOIO]: "/material-apoio",
  [routes.ACADEMY]: "/taya-academy",

  [routes.ESQUECI_SENHA]: "/esqueci-senha",
  [routes.TROCAR_SENHA]: "/trocar-senha/:token",
  [routes.PRIMEIRO_ACESSO_PARCEIRO]: "/primeiro-acesso/:token",
  [routes.DASHBOARD]: "/dashboard",
  [routes.CADASTRO_PARCEIRO]: "/cadastro-parceiro/:identificador?",
  [routes.PARCEIROS]: "/parceiros",
  [routes.DETALHES_PARCEIRO]: "/parceiro/:id",
  [routes.CLIENTES]: "/clientes",
  [routes.DETALHES_CLIENTE]: "/cliente/:id",
  [routes.OPERACOES]: "/operacoes",
  [routes.DETALHES_OPERACAO]: "/operacao/:id",
  [routes.APROVACAO_MENOR]: "/operacao/:id/aprovacao-menor",
  [routes.PREENCHIMENTO_OPERACAO]: "/operacao/:id/preenchimento",
  [routes.COMPLEMENTO_OPERACAO]: "/operacao/:id/complemento",
  [routes.CONFIRMACAO_VALORES]: "/operacao/:id/confirmacao-valores",
  [routes.CALCULADORA_INTERNA]: "/calculadora-interna",
  [routes.CALCULADORA_EXTERNA]: "/calculadora/:identificador/:produto?",
  [routes.SIMULADOR]: "/simulador",
  [routes.SIMULADOR_CONTINUACAO]: "/simulador/:id",
  [routes.DADOS_BANCARIOS]: "/operacao/:id/dados-bancarios"
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: "/error",
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? undefined;

    if (!route) {
      dispatch(routeActions.rejectTo(routes.LOGIN));
    } else if (route.restricted !== undefined) {
      const state = getState();
      if (!isUsuarioLogado(state)) {
        dispatch(
          routeActions.rejectTo(routes.LOGIN, {
            query: {
              redirect_url: btoa(window.location.href),
            },
          })
        );
      } else {
        const perfil = getPerfil(state);
        const usuarioLogado = getUsuarioLogado(state);
        const codigo = getCodigoOperacaoExterno(state);
        if (!canAccessRestrictedRoute(action, route, perfil, usuarioLogado)) {
          toast.error("Acesso Negado");
          if (perfil === "EXTERNO") {
            dispatch(
              routeActions.rejectTo(routes.LOGIN_EXTERNO, { id: codigo })
            );
          } else {
            dispatch(routeActions.rejectTo(routes.LOGIN));
          }
        }
      }
    }
  },
});

export { reducer, middleware, enhancer };

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};

const canAccessRestrictedRoute = (action, route, perfil, usuario) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (typeof route.restricted === "function") {
    return route.restricted(perfil);
  }
  if (Array.isArray(route.restricted)) {
    if (
      route.restricted.length === 0 ||
      route.restricted.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export default Container;
