import { actions, types } from "../actions/operacao.actions";
import { actions as usuarioActions } from "../actions/usuarios.actions";
import { types as routes } from "../actions/rotas.actions";
import { actions as documentoActions } from "../actions/documentos.actions";

const initialState = {
  resumo: {
    loading: true,
    data: null,
  },
  lista: {
    loading: true,
    paginaAtual: 0,
    totalElementos: 0,
    totalPaginas: 0,
    hasMore: true,
    filtros: {
      bancos: [],
      situacoes: [],
      parceiros: [],
      produtos: [],
      produtosWorkflow: [],
    },
    data: [],
  },
  dialogCompartilhamento: {
    show: false,
    data: null,
    loading: false,
  },
  dialogUltComentario: {
    show: false,
    data: null,
    submitting: false,
  },
  dialogAtribuirResponsavel: {
    show: false,
    data: null,
    loading: false,
    submitting: false,
  },
  detalhes: {
    tab: "0",
    loading: false,
    submitting: false,
    uploading: [],
    downloading: [],
    templating: [],
    deleting: [],
    downloadingAll: false,
    data: null,
    dominios: null,
  },
  preenchimento: {
    loading: false,
    adding: false,
    deleting: false,
    refreshing: false,
    searching: false,
    data: null,
    submitting: false,
    sending: false,
    relacionadas: {
      submitting: false,
    },
  },
  complemento: {
    loading: false,
    adding: false,
    deleting: false,
    refreshing: false,
    searching: false,
    data: null,
    submitting: false,
    sending: false,
  },
  dadosBancarios: {
    loading: false,
    data: null,
    sending: false,
  },
  confirmacao: {
    loading: false,
    data: null,
    sending: false,
  },
  aprovacaoMenor: {
    loading: false,
    data: null,
    sending: false,
  },
  conjuge: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.carregarResumo.REQUEST:
    case actions.carregarResumo.SUCCESS:
    case actions.carregarResumo.FAILURE:
      return {
        ...state,
        resumo: {
          ...state.resumo,
          loading: action.type === actions.carregarResumo.REQUEST,
          data:
            action.type === actions.carregarResumo.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };

    case actions.obterConjuge.REQUEST:
    case actions.obterConjuge.SUCCESS:
    case actions.obterConjuge.FAILURE:
      return {
        ...state,
        conjuge: action.type === actions.obterConjuge.REQUEST,
      };

    case usuarioActions.listarUsuariosPorParceiro.REQUEST:
      return {
        ...state,
        dialogAtribuirResponsavel: {
          ...state.dialogAtribuirResponsavel,
          loading: true,
        },
      };
    case usuarioActions.listarUsuariosPorParceiro.SUCCESS:
      return {
        ...state,
        dialogAtribuirResponsavel: {
          ...state.dialogAtribuirResponsavel,
          data: action.payload.response.data,
          loading: false,
        },
      };

    case actions.compartilharOperacao.REQUEST:
      return {
        ...state,
        dialogCompartilhamento: {
          ...initialState.dialogCompartilhamento,
          loading: true,
        },
      };
    case actions.compartilharOperacao.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            codigoOperacaoExterno: action.payload.response.data,
          },
        },
      };
    case actions.atualizarImovelDefinido.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            detalhe: {
              ...state.detalhes.data.detalhe,
              imovelDefinido: !state.detalhes.data.detalhe.imovelDefinido,
            },
          },
        },
      };
    case types.EXIBIR_DIALOG_COMPARTILHAMENTO:
      return {
        ...state,
        dialogCompartilhamento: {
          ...state.dialogCompartilhamento,
          show: action.payload.show,
          data: action.payload.data,
          loading: false,
        },
      };

    case actions.atualizarResponsavel.SUCCESS:
      return {
        ...state,
        dialogAtribuirResponsavel: {
          ...state.dialogAtribuirResponsavel,
          show: false,
        },
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            nomeContatoParceiro:
              action.payload.response.data.nomeContatoParceiro,
            telefoneParceiro: action.payload.response.data.telefoneParceiro,
          },
        },
      };

    case types.EXIBIR_DIALOG_ATRIBUIR_RESPONSAVEL:
      return {
        ...state,
        dialogAtribuirResponsavel: {
          ...state.dialogAtribuirResponsavel,
          show: action.payload.show,
        },
      };
    case routes.OPERACOES:
      return {
        ...state,
        lista: initialState.lista,
      };
    case actions.listarOperacoes.REQUEST:
      return {
        ...state,
        lista: {
          ...state.lista,
          paginaAtual: action.payload.clearData ? 0 : state.lista.paginaAtual,
          totalElementos: action.payload.clearData
            ? 0
            : state.lista.totalElementos,
          totalPaginas: action.payload.clearData ? 0 : state.lista.totalPaginas,
          hasMore: action.payload.clearData ? true : state.lista.hasMore,
          data: action.payload.clearData ? [] : state.lista.data,
          loading: true,
        },
      };
    case actions.listarOperacoes.FAILURE:
      return {
        ...state,
        lista: {
          ...initialState.lista,
          loading: false,
        },
      };
    case actions.listarOperacoes.SUCCESS:
      return {
        ...state,
        lista: {
          ...state.lista,
          loading: false,
          totalPaginas: action.payload.response.data.totalPaginas,
          totalElementos:
            state.lista.totalElementos === 0
              ? action.payload.response.data.totalElementos
              : state.lista.totalElementos,
          data: state.lista.data.concat(action.payload.response.data.conteudo),
          paginaAtual: action.payload.values.page + 1,
          hasMore:
            state.lista.paginaAtual !==
            action.payload.response.data.totalPaginas - 1,
        },
      };

    case actions.obterFiltrosOperacoes.SUCCESS:
      return {
        ...state,
        lista: {
          ...state.lista,
          filtros: action.payload.response.data,
        },
      };

    case actions.obterOperacao.REQUEST:
    case actions.obterOperacao.SUCCESS:
    case actions.obterOperacao.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loading: action.type === actions.obterOperacao.REQUEST,
          data:
            action.type === actions.obterOperacao.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.obterDominiosOperacao.REQUEST:
    case actions.obterDominiosOperacao.SUCCESS:
    case actions.obterDominiosOperacao.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          dominios:
            action.type === actions.obterDominiosOperacao.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.refresh.REQUEST:
    case actions.refresh.SUCCESS:
    case actions.refresh.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.refresh.SUCCESS
              ? action.payload.response.data
              : state.detalhes.data,
        },
      };
    case actions.uploadDocumento.REQUEST:
    case actions.uploadDocumento.SUCCESS:
    case actions.uploadDocumento.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          uploading:
            action.type === actions.uploadDocumento.REQUEST
              ? [...state.detalhes.uploading, action.payload.id]
              : state.detalhes.uploading.filter(
                (c) => c !== action.payload.original.id
              ),
          data:
            action.type === actions.uploadDocumento.SUCCESS
              ? {
                ...state.detalhes.data,
                checklists: state.detalhes.data.checklists.map((c) => ({
                  ...c,
                  grupos: c.grupos.map((g) => ({
                    ...g,
                    itens: g.itens.map((i) => ({
                      ...i,
                      documentos: i.documentos.map((d) =>
                        d.id === action.payload.response.data.id
                          ? {
                            ...action.payload.response.data,
                          }
                          : d
                      ),
                    })),
                  })),
                })),
              }
              : state.detalhes.data,
        },
      };
    case actions.obterDocumentos.REQUEST:
    case actions.obterDocumentos.SUCCESS:
    case actions.obterDocumentos.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loadingDocumentos: action.type === actions.obterDocumentos.REQUEST,
          data: {
            ...state.detalhes.data,
            checklists:
              action.type === actions.obterDocumentos.SUCCESS
                ? action.payload.response.data
                : state.detalhes.data.checklists,
          },
        },
      };
    case actions.atualizarDocumentos.REQUEST:
    case actions.atualizarDocumentos.SUCCESS:
    case actions.atualizarDocumentos.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loadingDocumentos:
            action.type === actions.atualizarDocumentos.REQUEST,
          data: {
            ...state.detalhes.data,
            checklists:
              action.type === actions.atualizarDocumentos.SUCCESS
                ? action.payload.response.data
                : state.detalhes.data.checklists,
          },
        },
      };
    case actions.downloadTodosDocumentos.REQUEST:
    case actions.downloadTodosDocumentos.SUCCESS:
    case actions.downloadTodosDocumentos.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          downloadingAll:
            action.type === actions.downloadTodosDocumentos.REQUEST,
        },
      };
    case actions.downloadDocumento.REQUEST:
    case actions.downloadDocumento.SUCCESS:
    case actions.downloadDocumento.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          downloading:
            action.type === actions.downloadDocumento.REQUEST
              ? [...state.detalhes.downloading, action.payload.id]
              : state.detalhes.downloading.filter(
                (c) => c !== action.payload.original.id
              ),
        },
      };
    case actions.downloadTemplate.REQUEST:
    case actions.downloadTemplate.SUCCESS:
    case actions.downloadTemplate.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          templating:
            action.type === actions.downloadTemplate.REQUEST
              ? [...state.detalhes.templating, action.payload.id]
              : state.detalhes.templating.filter(
                (c) => c !== action.payload.original.id
              ),
        },
      };
    case actions.removerArquivo.REQUEST:
    case actions.removerArquivo.SUCCESS:
    case actions.removerArquivo.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          deleting:
            action.type === actions.removerArquivo.REQUEST
              ? [...state.detalhes.deleting, action.payload.id]
              : state.detalhes.deleting.filter(
                (c) => c !== action.payload.original.id
              ),
          data:
            action.type === actions.removerArquivo.SUCCESS
              ? {
                ...state.detalhes.data,
                checklists: state.detalhes.data.checklists.map((c) => ({
                  ...c,
                  grupos: c.grupos.map((g) => ({
                    ...g,
                    itens: g.itens.map((i) => ({
                      ...i,
                      documentos: i.documentos.map((d) =>
                        d.id === action.payload.response.data.id
                          ? {
                            ...action.payload.response.data,
                          }
                          : d
                      ),
                    })),
                  })),
                })),
              }
              : state.detalhes.data,
        },
      };
    case actions.obterPreenchimento.REQUEST:
    case actions.obterPreenchimento.SUCCESS:
    case actions.obterPreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          loading: action.type === actions.obterPreenchimento.REQUEST,
          data:
            action.type === actions.obterPreenchimento.SUCCESS
              ? action.payload.response.data
              : null,
        },
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.obterPreenchimento.SUCCESS
              ? action.payload.response.data.operacao
              : null,
        },
      };
    case actions.adicionarParticipantePreenchimento.REQUEST:
    case actions.adicionarParticipantePreenchimento.SUCCESS:
    case actions.adicionarParticipantePreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          refreshing:
            action.type !== actions.adicionarParticipantePreenchimento.FAILURE,
          adding:
            action.type === actions.adicionarParticipantePreenchimento.REQUEST,
        },
      };
    case actions.atualizarParticipantePreenchimento.REQUEST:
    case actions.atualizarParticipantePreenchimento.SUCCESS:
    case actions.atualizarParticipantePreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          searching:
            action.type === actions.atualizarParticipantePreenchimento.REQUEST,
          data:
            action.type ===
              actions.atualizarParticipantePreenchimento.SUCCESS &&
              !!action.payload.response.data.operacao
              ? action.payload.response.data
              : state.preenchimento.data,
        },
      };
    case actions.removerParticipantePreenchimento.REQUEST:
    case actions.removerParticipantePreenchimento.SUCCESS:
    case actions.removerParticipantePreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          refreshing:
            action.type !== actions.removerParticipantePreenchimento.FAILURE,
          deleting:
            action.type === actions.removerParticipantePreenchimento.REQUEST,
        },
      };
    case actions.refreshPreenchimento.REQUEST:
    case actions.refreshPreenchimento.SUCCESS:
    case actions.refreshPreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          refreshing: action.type === actions.refreshPreenchimento.REQUEST,
          data:
            action.type === actions.refreshPreenchimento.SUCCESS
              ? action.payload.response.data
              : state.preenchimento.data,
        },
      };
    case actions.gravarPreenchimento.REQUEST:
    case actions.gravarPreenchimento.SUCCESS:
    case actions.gravarPreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          submitting: action.type === actions.gravarPreenchimento.REQUEST,
          data:
            action.type === actions.gravarPreenchimento.SUCCESS
              ? action.payload.response.data
              : state.preenchimento.data,
        },
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.gravarPreenchimento.SUCCESS
              ? action.payload.response.data.operacao
              : state.detalhes.data,
        },
      };
    case actions.enviarPreenchimento.REQUEST:
    case actions.enviarPreenchimento.SUCCESS:
    case actions.enviarPreenchimento.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          sending: action.type === actions.enviarPreenchimento.REQUEST,
        },
      };
    case actions.iniciarRelacionada.REQUEST:
    case actions.iniciarRelacionada.SUCCESS:
    case actions.iniciarRelacionada.FAILURE:
      return {
        ...state,
        preenchimento: {
          ...state.preenchimento,
          relacionadas: {
            ...state.preenchimento.relacionadas,
            submitting: action.type === actions.iniciarRelacionada.REQUEST,
          },
        },
      };
    case actions.obterComplemento.REQUEST:
    case actions.obterComplemento.SUCCESS:
    case actions.obterComplemento.FAILURE:
      return {
        ...state,
        complemento: {
          ...state.complemento,
          loading: action.type === actions.obterComplemento.REQUEST,
          data:
            action.type === actions.obterComplemento.SUCCESS
              ? action.payload.response.data
              : null,
        },
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.obterComplemento.SUCCESS
              ? action.payload.response.data.operacao
              : null,
        },
      };
    case actions.gravarComplemento.REQUEST:
    case actions.gravarComplemento.SUCCESS:
    case actions.gravarComplemento.FAILURE:
      return {
        ...state,
        complemento: {
          ...state.complemento,
          submitting: action.type === actions.gravarComplemento.REQUEST,
          data:
            action.type === actions.gravarComplemento.SUCCESS
              ? action.payload.response.data
              : state.complemento.data,
        },
      };
    case actions.enviarComplemento.REQUEST:
    case actions.enviarComplemento.SUCCESS:
    case actions.enviarComplemento.FAILURE:
      return {
        ...state,
        complemento: {
          ...state.complemento,
          sending: action.type === actions.enviarComplemento.REQUEST,
        },
      };
    case actions.adicionarParticipanteComplemento.REQUEST:
    case actions.adicionarParticipanteComplemento.SUCCESS:
    case actions.adicionarParticipanteComplemento.FAILURE:
      return {
        ...state,
        complemento: {
          ...state.complemento,
          refreshing:
            action.type !== actions.adicionarParticipanteComplemento.FAILURE,
          adding:
            action.type === actions.adicionarParticipanteComplemento.REQUEST,
        },
      };
    case actions.removerParticipanteComplemento.REQUEST:
    case actions.removerParticipanteComplemento.SUCCESS:
    case actions.removerParticipanteComplemento.FAILURE:
      return {
        ...state,
        complemento: {
          ...state.complemento,
          refreshing:
            action.type !== actions.removerParticipanteComplemento.FAILURE,
          deleting:
            action.type === actions.removerParticipanteComplemento.REQUEST,
        },
      };
    case actions.refreshComplemento.REQUEST:
    case actions.refreshComplemento.SUCCESS:
    case actions.refreshComplemento.FAILURE:
      return {
        ...state,
        complemento: {
          ...state.complemento,
          refreshing: action.type === actions.refreshComplemento.REQUEST,
          data:
            action.type === actions.refreshComplemento.SUCCESS
              ? action.payload.response.data
              : state.complemento.data,
        },
      };
    case documentoActions.gravarLaudoAvaliacao.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            detalhe: action.payload.response.data.detalheOperacao,
            checklists: state.detalhes.data.checklists.map((c) => ({
              ...c,
              grupos: c.grupos.map((g) => ({
                ...g,
                itens: g.itens.map((i) => ({
                  ...i,
                  documentos: i.documentos.map((d) =>
                    d.id === action.payload.response.data.documento.id
                      ? {
                        ...action.payload.response.data.documento,
                      }
                      : d
                  ),
                })),
              })),
            })),
          },
        },
      };
    case documentoActions.gravarValidadeCredito.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            detalhe: action.payload.response.data.detalheOperacao,
            checklists: state.detalhes.data.checklists.map((c) => ({
              ...c,
              grupos: c.grupos.map((g) => ({
                ...g,
                itens: g.itens.map((i) => ({
                  ...i,
                  documentos: i.documentos.map((d) =>
                    d.id === action.payload.response.data.documento.id
                      ? {
                        ...action.payload.response.data.documento,
                      }
                      : d
                  ),
                })),
              })),
            })),
          },
        },
      };
    case documentoActions.gravarCondicoesComerciais.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            detalhe: action.payload.response.data.detalheOperacao,
            checklists: state.detalhes.data.checklists.map((c) => ({
              ...c,
              grupos: c.grupos.map((g) => ({
                ...g,
                itens: g.itens.map((i) => ({
                  ...i,
                  documentos: i.documentos.map((d) =>
                    d.id === action.payload.response.data.documento.id
                      ? {
                        ...action.payload.response.data.documento,
                      }
                      : d
                  ),
                })),
              })),
            })),
          },
        },
      };
    case documentoActions.gravarIA.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data: {
            ...state.detalhes.data,
            checklists: state.detalhes.data.checklists.map((c) => ({
              ...c,
              grupos: c.grupos.map((g) => ({
                ...g,
                itens: g.itens.map((i) => ({
                  ...i,
                  documentos: i.documentos.map((d) =>
                    d.id === action.payload.response.data.id
                      ? {
                        ...action.payload.response.data,
                      }
                      : d
                  ),
                })),
              })),
            })),
          },
        },
      };
    case actions.obterConfirmacaoValores.REQUEST:
    case actions.obterConfirmacaoValores.SUCCESS:
    case actions.obterConfirmacaoValores.FAILURE:
      return {
        ...state,
        confirmacao: {
          ...state.confirmacao,
          loading: action.type === actions.obterConfirmacaoValores.REQUEST,
          data:
            action.type === actions.obterConfirmacaoValores.SUCCESS
              ? action.payload.response.data
              : null,
        },
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.obterConfirmacaoValores.SUCCESS
              ? action.payload.response.data.operacao
              : null,
        },
      };
    case actions.obterDadosBancarios.REQUEST:
    case actions.obterDadosBancarios.SUCCESS:
    case actions.obterDadosBancarios.FAILURE:
      return {
        ...state,
        dadosBancarios: {
          ...state.dadosBancarios,
          loading: action.type === actions.obterDadosBancarios.REQUEST,
          data:
            action.type === actions.obterDadosBancarios.SUCCESS
              ? action.payload.response.data
              : null,
        },
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.obterDadosBancarios.SUCCESS
              ? action.payload.response.data.operacao
              : null,
        },
      };
    case actions.enviarDadosBancarios.REQUEST:
    case actions.enviarDadosBancarios.SUCCESS:
    case actions.enviarDadosBancarios.FAILURE:
      return {
        ...state,
        dadosBancarios: {
          ...state.dadosBancarios,
          sending: action.type === actions.enviarDadosBancarios.REQUEST,
        },
      };
    case actions.enviarConfirmacaoValores.REQUEST:
    case actions.enviarConfirmacaoValores.SUCCESS:
    case actions.enviarConfirmacaoValores.FAILURE:
      return {
        ...state,
        confirmacao: {
          ...state.confirmacao,
          sending: action.type === actions.enviarConfirmacaoValores.REQUEST,
        },
      };
    case actions.obterOperacaoAprovacaoMenor.REQUEST:
    case actions.obterOperacaoAprovacaoMenor.SUCCESS:
    case actions.obterOperacaoAprovacaoMenor.FAILURE:
      return {
        ...state,
        aprovacaoMenor: {
          sending: false,
          loading: action.type === actions.obterOperacaoAprovacaoMenor.REQUEST,
          data:
            action.type === actions.obterOperacaoAprovacaoMenor.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };

    case types.EXIBIR_ULT_COMENTARIO:
      return {
        ...state,
        dialogUltComentario: {
          ...state.dialogUltComentario,
          show: action.payload.show,
          data: action.payload.data,
        },
      };
    case routes.DETALHES_OPERACAO:
      return {
        ...state,
        detalhes: {
          ...initialState.detalhes,
          loading: true,
          tab: action._secret?.tab ?? "0",
        },
      };
    case routes.PREENCHIMENTO_OPERACAO:
      return {
        ...state,
        preenchimento: {
          ...initialState.preenchimento,
          loading: false,
        },
      };
    case routes.COMPLEMENTO_OPERACAO:
      return {
        ...state,
        complemento: {
          ...initialState.complemento,
          loading: false,
        },
      };
    case routes.DADOS_BANCARIOS:
      return {
        ...state,
        complemento: {
          ...initialState.complemento,
          loading: false,
        },
      };
    case routes.CONFIRMACAO_VALORES:
      return {
        ...state,
        confirmacao: {
          ...initialState.confirmacao,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
